<template>
  <div id="page-user-edit" class="flex">
    <!-- تفاصيل المالك -->
    <div class="w-full">
      <vs-alert
        color="danger"
        title="المستخدم غير موجود"
        :active.sync="user_not_found"
      >
        <span>لم يتم العثور على سجل المالك بالمعرف: {{ $route.params.ID }}. </span>
        <span>
          <span>الرجاء </span>
          <router-link
            :to="{ name: 'page-user-list' }"
            class="text-inherit underline"
          >جميع مالكي الوحدات</router-link>
        </span>
      </vs-alert>
      <div class="flex">
        <vx-card class="mb-4" color="primary" elevation="2">
          <h3 style="padding-bottom: 10px;">{{ $t("معلومات المستخدم") }}</h3>
          <div class="mb-4">
            <div v-for="(value, key) in userData" :key="key" class="flex items-center mb-2">
              <i class="feather icon" :class="getIcon(key)"></i>
              <span class="ml-2">{{ value }}</span>
            </div>
          </div>
        </vx-card>
        <vx-card class="mb-4" color="primary" elevation="2">
          <h3 style="padding-bottom: 10px;">{{ $t("دور المستخدم") }}</h3>
          <div class="mb-4">
            <ul style="padding-bottom: 10px;">
              <li style="padding-bottom: 10px;">تواصل مع العملاء</li>
              <li style="padding-bottom: 10px;">الحسابات</li>
              <li style="padding-bottom: 10px;">مقابلة العملاء</li>
              <li style="padding-bottom: 10px;">تعديل بيانات العملاء</li>
            </ul>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import VxCard from "../../components/vx-card/VxCard.vue";

export default {
  components: {
    VxCard
  },
  data() {
    return {
      user_not_found: false,
      userData: {
        ownerName: "أميرة مصطفى",
        address: "شارع رمسيس، الإسكندرية",
        phoneNumber: "+123456789",
        Email: "JohnDoe@gmail.com",
        status: "نشط",
        nationalCardNumber: "1234567890",
      },
    };
  },
  methods: {
    getIcon(key) {
      switch (key) {
        case "ownerName":
          return "icon-user";
        case "address":
          return "icon-map-pin";
        case "phoneNumber":
          return "icon-phone";
        case "Email":
          return "icon-mail";
        case "status":
          return "icon-check-circle";
        case "nationalCardNumber":
          return "icon-credit-card";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.vx-card {
  flex: 1;
  margin-right: 10px;
}
.mb-4 {
  margin-bottom: 1rem;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.feather {
  font-size: 1.2rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
